// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ClanSelectionScreen from "./ClanSelectionScreen";
import UserDashboardScreen from "./UserDashboardScreen";
import Preloader from "./Preloader";

const tg = window.Telegram.WebApp;


const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
        tg.ready();
    }, []);

    return (
        <Router>
            {loading ? (
                <Preloader />
            ) : (
                <Routes>
                    <Route path="/" element={<ClanSelectionScreen />} />
                    <Route path="/dashboard" element={<UserDashboardScreen />} />
                </Routes>
            )}
        </Router>
    );
};

export default App;
