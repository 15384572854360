// UserDashboardScreen.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const tg = window.Telegram.WebApp;

const UserDashboardScreen = () => {
    const location = useLocation();
    const [userData, setUserData] = useState({ nickname: "", balance: 0 });
    const [friendsCount, setFriendsCount] = useState(0);


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://api.etena.io/user/me?${tg.initData}`);
                setUserData(response.data);
                const friendsResponse = await axios.get(`https://api.etena.io/user/referral?${tg.initData}`);
                console.log(friendsResponse)
                setFriendsCount(friendsResponse.data);
            } catch (error) {
                console.error("Ошибка при запросе к API", error);
            }
        };
        fetchUserData();
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <span style={styles.nickname}>{userData.first_name} : {userData.clan_value}</span>
                <span style={styles.balance}>{userData.balance}</span>
            </div>
            <div style={styles.buttonContainer}>
                <button style={styles.button}>Нажми на меня</button>
            </div>
            <div style={styles.friendsCount}>
                У вас {friendsCount} рефералов
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginBottom: "20px",
    },
    nickname: {
        fontSize: "18px",
        fontWeight: "bold",
    },
    balance: {
        fontSize: "18px",
        fontWeight: "bold",
        color: "#3498db",
    },
    buttonContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#3498db",
        color: "white",
        fontSize: "16px",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    },
    friendsCount: {
        textAlign: "center",
        fontSize: "16px",
        color: "#2c3e50",
        // marginTop: "20px",
        paddingBottom: "40px"
    },
};

export default UserDashboardScreen;