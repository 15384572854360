// ClanSelectionScreen.js
import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const clans = ["WATER", "FIRE", "ROCK", "WIND"];
const tg = window.Telegram.WebApp;

const ClanSelectionScreen = () => {
    const navigate = useNavigate();
    const [activeClan, setActiveClan] = useState(null);
    const [text, setText] = useState(null);
    const textareaRef = useRef(null);

    const handleCopy = () => {
        // Проверяем, что ссылка на textarea существует
        if (textareaRef.current) {
            // Выбираем весь текст
            textareaRef.current.select();
            // Копируем выбранный текст в буфер обмена
            document.execCommand('copy');
            // Выводим уведомление, если нужно
            alert('Текст скопирован в буфер обмена!');
        }
    };

    const selectClan = async (clan) => {
        setActiveClan(clan);
        try {
            const response = await axios.post(`https://api.etena.io/user/clan?${tg.initData}`, {value: clan})
                .catch(e => setText(JSON.stringify(e)));
            window.scrollTo(0, 0);
            // navigate("/dashboard", { state: { clanData: "WATER" } });
            navigate("/dashboard", { state: { clanData: response.data } });
        } catch (error) {
            console.error("Ошибка при запросе к API", error);
        }
    };

    return (
        <div >
            <div style={styles.container}>
                <div style={styles.row}>
                    {clans.slice(0, 2).map((clan, index) => (
                        <div
                            key={index}
                            style={activeClan === clan ? {...styles.clanBox, ...styles.clanBoxActive} : styles.clanBox}
                            onClick={() => selectClan(clan)}
                        >
                            {clan}
                        </div>
                    ))}
                </div>
                <div style={styles.row}>
                    {clans.slice(2, 4).map((clan, index) => (
                        <div
                            key={index}
                            style={activeClan === clan ? {...styles.clanBox, ...styles.clanBoxActive} : styles.clanBox}
                            onClick={() => selectClan(clan)}
                        >
                            {clan}
                        </div>
                    ))}
                </div>
            </div>
             <div style={styles.test}>
      <textarea
          ref={textareaRef}
          defaultValue="Т"
          rows="4"
          cols="50"
          style={{marginBottom: '10px'}}
          value={text}
      />       <button onClick={handleCopy}>Копировать текст</button>
             </div>

        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f4f4f4",
    },
    test: {
      backgroundColor: "white",
      color: "black",
    },
    row: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
    },
    clanBox: {
        width: "150px",
        height: "150px",
        backgroundColor: "#3498db",
        margin: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "20px",
        fontWeight: "bold",
        cursor: "pointer",
        borderRadius: "10px",
        transition: "transform 0.2s, box-shadow 0.2s",
    },
    clanBoxActive: {
        transform: "scale(0.95)",
        backgroundColor: "#04578d",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    },
};

export default ClanSelectionScreen;
